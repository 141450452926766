.h1wjqlg3{font-size:6rem;font-weight:900;margin:var(--h1wjqlg3-0);}
.h8wb8ef{font-size:3.75rem;font-weight:700;margin:var(--h8wb8ef-0);}
.h1cva1nn{font-size:3rem;font-weight:700;margin:var(--h1cva1nn-0);}
.hh57m7w{font-size:2.125rem;font-weight:500;margin:var(--hh57m7w-0);}
.h4hbfm7{font-size:1.5rem;font-weight:500;margin:var(--h4hbfm7-0);}
.hyfjb5d{font-size:1.25rem;font-weight:500;margin:var(--hyfjb5d-0);}
.s1o4wlvh{font-size:1rem;font-weight:500;margin:var(--s1o4wlvh-0);}
.s1c2p9yj{font-size:0.875rem;font-weight:500;line-height:1.35;margin:var(--s1c2p9yj-0);}
.b15h7jub{font-size:1rem;line-height:1.35;margin:var(--b15h7jub-0);}
.bh4t3on{font-size:0.875rem;line-height:1.35;margin:var(--bh4t3on-0);}
